import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';
import { snakeCase } from 'lodash';
import { z } from 'zod';

import { ValidationResult } from 'hooks/useForm';

const commonDataFieldSchema = z.object({
  id: z.number().optional(),
  key: z.string().trim().min(1),
  question: z.string().trim().min(1),
  position: z.number().gte(0),
  required: z.boolean().default(true),
  hint: z.string().optional(),
  // we are making use of some rails magic here to handle the delete diffs
  // upside no complex logic needed on the BE to identify what was deletes
  // downside is the FE needs to account for this property and add it/filter
  // it accordingly where needed
  _destroy: z.boolean().optional(),
});

const textFieldSchema = commonDataFieldSchema.extend({
  type: z.literal(ReviewStageDataFieldTypeEnum.textField),
});
const flexibleTextFieldSchema = commonDataFieldSchema
  .extend({
    type: z.literal(ReviewStageDataFieldTypeEnum.textField),
    key: z.string().optional(),
  })
  .transform(({ question, ...values }) => ({
    ...values,
    question,
    key: snakeCase(question),
  }));

const radioFieldSchema = commonDataFieldSchema.extend({
  type: z.literal(ReviewStageDataFieldTypeEnum.radioField),
  options: z
    .array(
      z.object({
        _id: z.string(),
        value: z.string().trim().min(1),
        label: z.string().trim().min(1),
        visible: z.boolean().default(true),
      }),
    )
    .min(1, { message: 'at least 1 choice is required' }),
});

const flexibleRadioFieldSchema = commonDataFieldSchema
  .extend({
    type: z.literal(ReviewStageDataFieldTypeEnum.radioField),
    key: z.string().optional(),
    options: z
      .array(
        z.object({
          _id: z.string(),
          value: z.string().trim().min(1),
          label: z.string().trim().min(1),
          visible: z.boolean().default(true),
        }),
      )
      .min(1, { message: 'at least 1 choice is required' }),
  })
  .transform(({ question, ...values }) => ({
    ...values,
    question,
    key: snakeCase(question),
  }));

// we need to do this funky pipe with a flexible schema bc
// zod doesnt support doing transform with discriminated unions
export const dataFieldSchema = z
  .union([flexibleRadioFieldSchema, flexibleTextFieldSchema])
  .pipe(z.discriminatedUnion('type', [textFieldSchema, radioFieldSchema]));

export type DataField = z.infer<typeof dataFieldSchema>;
export type RadioDataField = Extract<
  DataField,
  { type: ReviewStageDataFieldTypeEnum.radioField }
>;
export type TextDataField = Extract<
  DataField,
  { type: ReviewStageDataFieldTypeEnum.textField }
>;
// we need to retype what errors look like for an array as the current
// generic doesnt account for such use cases
export type RadioErrors = Omit<ValidationResult<RadioDataField>, 'options'> & {
  options?: Record<number, string>;
};

const formBuilderSchema = z.object({
  dataFields: z.array(dataFieldSchema),
});

export type FormBuilder = z.infer<typeof formBuilderSchema>;
