import { defineMessages } from 'react-intl';

export const questionnaireDisplayMessages = defineMessages({
  editButtonText: {
    id: `app.components.FormBuilder.editButtonText`,
    defaultMessage: 'edit',
  },
  deleteButtonText: {
    id: `app.components.FormBuilder.deleteButtonText`,
    defaultMessage: 'delete',
  },
});

export const formBuilderMessages = defineMessages({
  header: {
    id: `app.components.FormBuilder.header`,
    defaultMessage: 'Questions',
  },
  addQuestionText: {
    id: `app.components.FormBuilder.addQuestionText`,
    defaultMessage: 'Add question',
  },
  editQuestionText: {
    id: `app.components.FormBuilder.editQuestionText`,
    defaultMessage: 'Edit question',
  },
  updateQuestionText: {
    id: `app.components.FormBuilder.updateQuestionText`,
    defaultMessage: 'Update question',
  },
});
