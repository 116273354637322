import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';

import { DataField, TextDataField } from 'components/FormBuilder/schemas';

export const createDefaultCommonField = (
  position: number,
): Omit<DataField, 'type'> => ({
  question: '',
  hint: '',
  key: '',
  position,
  required: true,
});

export const createDefaultTextField = (position: number): TextDataField => ({
  type: ReviewStageDataFieldTypeEnum.textField,
  ...createDefaultCommonField(position),
});
