import { EventAvailableSlot } from 'api-clients/monolith';

export interface splitOption {
  label: string;
  value: number;
}

export interface CreateEventProps {
  scrollParent?: HTMLDivElement | null;
  event: EventAvailableSlot;
  isUpdate?: boolean;
}

export interface FooterProps {
  isUpdatingEvent: boolean;
  isSaveButtonEnabled: boolean;
  resetForm: (valueOverrides?: Partial<EventAvailableSlot> | undefined) => void;
  onSubmit: (mode: UpdateModeType) => void;
  redirectToCalendar: () => void;
  availableSlot: EventAvailableSlot;
}

export const UpdateModeTypes = {
  UNBOOKED: 'unbooked',
  ALL: 'all',
  SINGLE: 'single',
} as const;

export type UpdateModeType =
  (typeof UpdateModeTypes)[keyof typeof UpdateModeTypes];
