import { Input, StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';
import React from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { useFormBuilderContextState } from 'components/FormBuilder/context';
import { useWorkflowReviewStage } from 'components/FormBuilder/hooks';

import { createDefaultRadioField } from '../MultipleChoice/helpers';
import { QUESTION_TYPE_OPTIONS, QuestionTypeOptions } from './constants';
import { createDefaultTextField } from './helpers';
import { commonQuestionFormMessages } from './messages';

const {
  questionFormQuestionTypeLabel,
  questionFormQuestionLabel,
  questionFormQuestionHintLabel,
} = commonQuestionFormMessages;

type CreateDefaultFns =
  | typeof createDefaultRadioField
  | typeof createDefaultTextField;

const TYPE_TO_DEFAULT: Record<ReviewStageDataFieldTypeEnum, CreateDefaultFns> =
  {
    radio: createDefaultRadioField,
    text_field: createDefaultTextField,
  };
export const CommonQuestionsForm = () => {
  const intl = useIntl();
  const { activeDataEntries } = useWorkflowReviewStage();
  const { values, errors, handleChange } = useFormBuilderContextState();

  return (
    <>
      <Grid item xs={12}>
        <Input
          label={intl.formatMessage(questionFormQuestionLabel)}
          value={values.question}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange({
              question: event.target.value,
            })
          }
          error={Boolean(errors.question)}
          required
        />
        <Error error={errors.question} align="right" />
      </Grid>
      <Grid item xs={12}>
        <Input
          label={intl.formatMessage(questionFormQuestionHintLabel)}
          value={values.hint}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange({ hint: event.target.value })
          }
          error={Boolean(errors.hint)}
        />
        <Error error={errors.hint} align="right" />
      </Grid>
      <Grid item xs={12}>
        <StyledReactSelect
          label={intl.formatMessage(questionFormQuestionTypeLabel)}
          options={QUESTION_TYPE_OPTIONS}
          onChange={({ value }: QuestionTypeOptions[number]) => {
            const defaults = TYPE_TO_DEFAULT[value];
            handleChange(defaults(activeDataEntries.length), { replace: true });
          }}
          value={QUESTION_TYPE_OPTIONS.find(
            ({ value }) => value === values.type,
          )}
          isSearchable={false}
          error={Boolean(errors.type)}
          required
        />
        <Error error={errors.type} align="right" />
      </Grid>
    </>
  );
};
