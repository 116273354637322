/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicantBrandColors } from '../models/ApplicantBrandColors';
import type { AvailabilityQuery } from '../models/AvailabilityQuery';
import type { AvailableDay } from '../models/AvailableDay';
import type { AvailableSlot } from '../models/AvailableSlot';
import type { AvailableSlotDetail } from '../models/AvailableSlotDetail';
import type { AvailableTimeZone } from '../models/AvailableTimeZone';
import type { BackgroundCheckStatus } from '../models/BackgroundCheckStatus';
import type { BookedSlot } from '../models/BookedSlot';
import type { ConversionTracker } from '../models/ConversionTracker';
import type { DataFields } from '../models/DataFields';
import type { Experiment } from '../models/Experiment';
import type { FileUpload } from '../models/FileUpload';
import type { JobRouterPosition } from '../models/JobRouterPosition';
import type { LearningCourseAssignment } from '../models/LearningCourseAssignment';
import type { PartnerCompletedCopyKeys } from '../models/PartnerCompletedCopyKeys';
import type { PartnerConsents } from '../models/PartnerConsents';
import type { PortalStage } from '../models/PortalStage';
import type { Reschedulable } from '../models/Reschedulable';
import type { StageProgress } from '../models/StageProgress';
import type { SubStagePreview } from '../models/SubStagePreview';
import type { TechCheckStageSetting } from '../models/TechCheckStageSetting';
import type { VideoRecordingStageQuestion } from '../models/VideoRecordingStageQuestion';
import type { VideoRecordingStageRecordingAttributes } from '../models/VideoRecordingStageRecordingAttributes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApplicantPortalService {

    /**
     * Updates the applicant signatures
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param signatureId Signature Id
     * @param event Event
     * @returns any successful
     * @throws ApiError
     */
    public static patchInternalApiPortalApplicationsApplicantSignatures(
        accountSlug: string,
        applicationId: string,
        signatureId: string,
        event: string,
    ): CancelablePromise<{
        is_applicant_advanced: boolean;
        signature: {
            signature_id?: string;
            embedded_signature_request_id?: number;
            signer_type?: string;
            funnel_id?: number;
            signed_at?: string;
        };
        has_next_sub_stage?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/applicant_signatures/{signature_id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'signature_id': signatureId,
            },
            query: {
                'event': event,
            },
        });
    }

    /**
     * Returns the signature claim URL
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param signatureId Signature Id
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsApplicantSignatures(
        accountSlug: string,
        applicationId: string,
        signatureId: string,
    ): CancelablePromise<{
        url: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/applicant_signatures/{signature_id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'signature_id': signatureId,
            },
        });
    }

    /**
     * Updates applicant
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param requestBody Applicant update params
     * @param stageId Stage Override Id
     * @returns any successful
     * @throws ApiError
     */
    public static putInternalApiPortalApplicants(
        accountSlug: string,
        applicationId: string,
        requestBody: {
            time_zone?: string;
            ga_client_id?: string;
        },
        stageId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/portal/{account_slug}/applicants/{application_id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'stage_id': stageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid time zone`,
            },
        });
    }

    /**
     * Retrieves required data for a new application form
     * @param accountSlug Account Slug
     * @param funnelId Funnel Slug
     * @param tryNewUi Overrides feature flag to show the new UI
     * @param personaId Reach Profile External ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationFormsNew(
        accountSlug: string,
        funnelId?: string,
        tryNewUi?: string,
        personaId?: string,
    ): CancelablePromise<{
        account: {
            directory_path: string;
            is_amazon_account: boolean;
            is_ups_account?: boolean;
            external_id: string;
            name: string;
            account_type?: string;
        };
        brand: {
            name: string;
            is_amazon_dsp: boolean;
            external_id: string;
            slug: string;
            logos: {
                square_image_url?: string | null;
                image_url?: string | null;
            };
            navbar_text_color: string | null;
            colors: ApplicantBrandColors;
        };
        funnel: {
            application_form_title: string;
            country_code: string;
            custom_fields: DataFields;
            file_upload?: FileUpload;
            hide_back_to_positions_link: boolean;
            hide_job_wording: boolean;
            jobs_directory_disabled?: boolean;
            locale: string;
            display_locale?: string;
            alternative_locales: Record<string, boolean>;
            name_input_min_length: number;
            position_description_html: string;
            position_custom_success_message: string;
            title: string;
            address?: string;
            compensation?: string;
            company_support_email?: string;
            external_id?: string;
            slug?: string;
            location: {
                name: string;
                external_id: string;
            };
            sms_consent_variant: 'Standard' | 'UPS';
            shift?: {
                min_weekly_hours?: number;
                max_weekly_hours?: number;
                description: string;
            };
            active: boolean;
            job_at_a_glance?: Array<string>;
            job_hours?: string;
            position_name: string;
            job_type?: string;
            industry?: string;
            cover_image?: string;
            expand_job_description?: boolean;
        };
        config: {
            record_applicant: boolean;
            itc_demo_enabled: boolean;
            reach_application_form_enabled?: boolean;
            cookie_consent_enabled: boolean;
            turnstile_enabled: boolean;
            experiments: Array<Experiment>;
            conversion_trackers: Array<ConversionTracker>;
            validate_email: boolean;
            hide_email_field: boolean;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/application_forms/new',
            path: {
                'account_slug': accountSlug,
            },
            query: {
                'funnel_id': funnelId,
                'try_new_ui': tryNewUi,
                'persona_id': personaId,
            },
            errors: {
                421: `return to AX 1.0`,
            },
        });
    }

    /**
     * Completes Application Form
     * @param accountSlug Account Slug
     * @param formData Application key value pairs
     * @param funnelId Funnel Slug
     * @returns any Success
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationForms(
        accountSlug: string,
        formData: {
            check_if_applicant_is_duplicate?: boolean;
            referred_by_applicant_id?: string;
            referral_id?: string;
            keyword_id?: number;
            short_code_applicant_id?: number;
            application_form: Record<string, string>;
            country_code?: string;
        },
        funnelId?: string,
    ): CancelablePromise<{
        applicant_external_id: string;
        is_duplicate: boolean;
        can_reapply?: boolean;
        launch_portal_from_form?: boolean;
        duplicate_message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/application_forms',
            path: {
                'account_slug': accountSlug,
            },
            query: {
                'funnel_id': funnelId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Sends link to most recent application to applicant
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @returns void
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationsApplicationRequests(
        accountSlug: string,
        applicationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/application_requests',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            errors: {
                422: `Message could not be sent`,
            },
        });
    }

    /**
     * Retrieves I9 Form Info about Applicant
     * @param accountSlug Account slug
     * @param i9FormId I9 Form External ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalAuthorizedRepresentativeForms(
        accountSlug: string,
        i9FormId: string,
    ): CancelablePromise<{
        applicant_first_name: string;
        applicant_last_name: string;
        applicant_middle_initial: string;
        applicant_citizenship_status: string;
        i9_form_version: string;
        is_i9_compliance_2023_enabled: boolean;
        employer_business_name: string;
        employer_address_street: string;
        employer_address_city: string;
        employer_address_state: string;
        employer_address_zip: string;
        upload_config: {
            storage?: string;
            record_id?: string;
            url: string;
            host: string;
            min_size: number;
            max_size: number;
            only_images: boolean;
            type: string;
            form_data: {
                key: string;
                success_action_status: string;
                acl: string;
                'x-amz-server-side-encryption-customer-algorithm'?: string;
                'x-amz-server-side-encryption-customer-key'?: string;
                'x-amz-server-side-encryption-customer-key-MD5'?: string;
                'policy?'?: string;
                'x-amz-credential'?: string;
                'x-amz-algorithm'?: string;
                'x-amz-date'?: string;
                'x-amz-signature'?: string;
            };
        };
        status: 'awaiting_employee' | 'awaiting_employer' | 'pending' | 'complete' | 'opted_out' | 'awaiting_authorized_representative';
        account: {
            directory_path: string;
            is_amazon_account: boolean;
            external_id: string;
            name: string;
        };
        brand: {
            name: string;
            external_id: string;
            slug: string;
            logos: {
                square_image_url?: string | null;
                image_url?: string | null;
            };
            navbar_text_color: string | null;
            colors: ApplicantBrandColors;
        };
        funnel: {
            application_form_title: string;
            country_code: string;
            custom_fields: DataFields;
            file_upload?: FileUpload;
            hide_back_to_positions_link: boolean;
            jobs_directory_disabled?: boolean;
            locale: string;
            name_input_min_length: number;
            position_description_html: string;
            position_custom_success_message: string;
            title: string;
            address?: string;
            compensation?: string;
            company_support_email?: string;
            external_id?: string;
            slug?: string;
            location: {
                name: string;
                external_id: string;
            };
        };
        config: {
            record_applicant: boolean;
            cookie_consent_enabled: boolean;
            experiments: Array<Experiment>;
            conversion_trackers: Array<ConversionTracker>;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/authorized_representative_forms/{i9_form_id}',
            path: {
                'account_slug': accountSlug,
                'i9_form_id': i9FormId,
            },
            errors: {
                404: `not found`,
            },
        });
    }

    /**
     * Updates the remote authorization portion of I9 Forms
     * @param accountSlug Account slug
     * @param i9FormId I9 Form External ID
     * @param formData Remote Authorization form key value pairs
     * @returns any updated
     * @throws ApiError
     */
    public static patchInternalApiPortalAuthorizedRepresentativeForms(
        accountSlug: string,
        i9FormId: string,
        formData: Record<string, Record<string, string>>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/portal/{account_slug}/authorized_representative_forms/{i9_form_id}',
            path: {
                'account_slug': accountSlug,
                'i9_form_id': i9FormId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `not found`,
            },
        });
    }

    /**
     * Sends I9 Form Email to Authorized Representative
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param requestBody Authorized Representive links
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationsAuthorizedRepresentativesEmail(
        accountSlug: string,
        applicationId: string,
        requestBody: {
            email: string;
            form_url: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/authorized_representatives/email',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable`,
            },
        });
    }

    /**
     * Gets status of Background Check invitation
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param stageId Stage Override Id
     * @returns BackgroundCheckStatus successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsBackgroundChecksStatus(
        accountSlug: string,
        applicationId: string,
        stageId?: string,
    ): CancelablePromise<BackgroundCheckStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/background_checks/status',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'stage_id': stageId,
            },
        });
    }

    /**
     * Create checkr candidate and consent
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param requestBody Candidate Params
     * @param stageId Stage Override Id
     * @returns void
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationsBackgroundChecks(
        accountSlug: string,
        applicationId: string,
        requestBody: {
            first_name?: string;
            middle_name?: string | null;
            last_name?: string;
            no_middle_name?: boolean;
            email?: string;
            phone?: string;
            date_of_birth?: string;
            social_security?: string;
            current_zip_code?: string;
            copy_requested?: boolean;
            driver_license_number?: string | null;
            driver_license_state?: string | null;
        },
        stageId?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/background_checks',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'stage_id': stageId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generates and returns secure public url
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param jsonQueryParams JSON string with parameters to lookup storage blob
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsBlobPublicUrl(
        accountSlug: string,
        applicationId: string,
        jsonQueryParams: string,
    ): CancelablePromise<{
        url: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/blob_public_url',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'json_query_params': jsonQueryParams,
            },
        });
    }

    /**
     * Retrieves Context for account and applicant
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param tryNewUi Overrides feature flag to show the new UI
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsContexts(
        accountSlug: string,
        applicationId: string,
        tryNewUi?: string,
    ): CancelablePromise<{
        account: {
            name: string;
            slug: string;
            external_id: string;
            account_type?: string;
            is_ups_account?: boolean;
        };
        brand: {
            external_id: string;
            slug: string;
            name: string;
            logo_url?: string | null;
            colors: ApplicantBrandColors;
        };
        config: {
            cookie_consent_enabled: boolean;
            experiments: Array<Experiment>;
            ax_faq_widget_enabled: boolean;
            chat_agent_widget_enabled: boolean;
            pusher_key: string;
            pusher_cluster: string;
        };
        funnel: {
            external_id: string;
            slug: string;
            title: string;
            locale: string;
            company_support_email: string | null;
            country_code?: string;
            location: {
                name: string;
                external_id: string;
                time_zone: string;
            };
            custom_fields?: DataFields;
            display_locale?: string;
            alternative_locales: Record<string, boolean>;
        };
        applicant: {
            time_zone: string | null;
            phone_number: string;
            application_locale?: string | null;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/contexts',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'try_new_ui': tryNewUi,
            },
            errors: {
                421: `return to AX 1.0`,
            },
        });
    }

    /**
     * Retrieves all countries
     * @returns string countries found
     * @throws ApiError
     */
    public static getInternalApiPortalCountries(): CancelablePromise<Array<Array<string>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/countries',
        });
    }

    /**
     * Retrieves all states for a country
     * @param id The Country ISO code
     * @returns string states found for country
     * @throws ApiError
     */
    public static getInternalApiPortalCountriesStates(
        id: string,
    ): CancelablePromise<Array<Array<string>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/countries/{id}/states',
            path: {
                'id': id,
            },
            errors: {
                404: `no states found for nonexisting country`,
            },
        });
    }

    /**
     * Returns the embedded signature url
     * @param accountSlug
     * @param applicationId
     * @param signatureId
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsDocumentSignatureRequests(
        accountSlug: string,
        applicationId: string,
        signatureId: string,
    ): CancelablePromise<{
        url: string | null;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/document_signature_requests/{signature_id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'signature_id': signatureId,
            },
        });
    }

    /**
     * Retrieves applicant file upload request data
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsFileUploadRequestsNew(
        accountSlug: string,
        applicationId: string,
    ): CancelablePromise<{
        upload_config: FileUpload;
        file_upload_requests: Array<{
            id: number;
            blob_id: number;
            recollection_reason: string;
            question: string;
            hint?: string | null;
            key: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/file_upload_requests/new',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
        });
    }

    /**
     * Update applicant file upload requests
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param requestBody File uploads
     * @returns void
     * @throws ApiError
     */
    public static patchInternalApiPortalApplicationsFileUploadRequests(
        accountSlug: string,
        applicationId: string,
        requestBody: {
            uploaded_files: Array<{
                blob_id: string;
                blob_size?: number;
                existing_blob_id: number;
            }>;
        },
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/file_upload_requests',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves autocomplete suggestions for an address
     * @param query Search phrase
     * @param location Search boundary
     * @param sessionId Maps temporary session ID
     * @param types Place Types
     * @param locationBias Biases a search to an area
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalGoogleMapsAutocomplete(
        query: string,
        location?: string,
        sessionId?: string,
        types?: string,
        locationBias?: string,
    ): CancelablePromise<{
        predictions: Array<{
            description: string;
            matched_substrings: Array<{
                length: number;
                offset: number;
            }>;
            place_id: string;
            reference: string;
            structured_formatting: {
                main_text: string;
                secondary_text: string;
                main_text_matched_substrings: Array<{
                    length: number;
                    offset: number;
                }>;
            };
            terms: Array<{
                offset: number;
                value: string;
            }>;
            types: Array<string>;
        }>;
        status: string;
        maps_session: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/google_maps/autocomplete',
            query: {
                'query': query,
                'location': location,
                'session_id': sessionId,
                'types': types,
                'location_bias': locationBias,
            },
        });
    }

    /**
     * Retrieves structured address information for a given place found by autocomplete
     * @param placeId ID of Place from Google Maps API
     * @param sessionId Maps temporary session ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalGoogleMapsDetails(
        placeId: string,
        sessionId: string,
    ): CancelablePromise<{
        result: {
            address_components: Array<{
                long_name: string;
                short_name: string;
                types: Array<string>;
            }>;
            geometry: {
                location: {
                    lat: number;
                    lng: number;
                };
            };
        };
        status: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/google_maps/details',
            query: {
                'place_id': placeId,
                'session_id': sessionId,
            },
        });
    }

    /**
     * creates an I-9 form
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param formData I9 Form key value pairs
     * @returns any success
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationsI9Forms(
        accountSlug: string,
        applicationId: string,
        formData: {
            i9_form: any;
            additionalProperties?: string;
        },
    ): CancelablePromise<{
        i9FormId: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/i9_forms',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `Failed to create I-9 Form`,
            },
        });
    }

    /**
     * updates an I-9 form
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param id I9 Form External ID
     * @param formData I9 Form key value pairs
     * @param updateStatus Whether the I-9 Form's status should be updated
     * @returns void
     * @throws ApiError
     */
    public static patchInternalApiPortalApplicationsI9Forms(
        accountSlug: string,
        applicationId: string,
        id: string,
        formData: {
            i9_form: any;
            additionalProperties?: string;
        },
        updateStatus?: boolean,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/i9_forms/{id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'id': id,
            },
            query: {
                'update_status': updateStatus,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `Failed to update I-9 Form`,
            },
        });
    }

    /**
     * returns the url of the pdf associated with the I-9 Form
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param i9FormId I9 Form External ID
     * @returns any success
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsI9FormsPdf(
        accountSlug: string,
        applicationId: string,
        i9FormId: string,
    ): CancelablePromise<{
        pdfUrl: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/i9_forms/{i9_form_id}/pdf',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'i9_form_id': i9FormId,
            },
            errors: {
                500: `I-9 PDF URL could not be retrieved`,
            },
        });
    }

    /**
     * Retrives BIC number and validation results for IBAN
     * @param iban IBAN number to validate, and extract BIC from
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalIbanCheckValidity(
        iban: string,
    ): CancelablePromise<{
        bic: string;
        validations: {
            chars: {
                code: string;
                message: string;
            };
            account: {
                code: string;
                message: string;
            };
            iban: {
                code: string;
                message: string;
            };
            structure: {
                code: string;
                message: string;
            };
            length: {
                code: string;
                message: string;
            };
            country_support: {
                code: string;
                message: string;
            };
        };
        errors: Array<{
            /**
             * Proprietary code for the API detailing error
             */
            code?: string;
            /**
             * Human readable description of error
             */
            message?: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/iban/check_validity',
            query: {
                'iban': iban,
            },
        });
    }

    /**
     * Checks Learning if assignment is available
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @returns LearningCourseAssignment successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsLearningCourseAssignmentStatus(
        accountSlug: string,
        applicationId: string,
    ): CancelablePromise<LearningCourseAssignment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/learning_course_assignment/status',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
        });
    }

    /**
     * Requests more slots from the recruiter
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param stageId Stage Override Id
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsScheduleSlotsRequestMoreSlots(
        accountSlug: string,
        applicationId: string,
        stageId?: string,
    ): CancelablePromise<{
        status: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots/request_more_slots',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'stage_id': stageId,
            },
        });
    }

    /**
     * Retrieves Days With Available Slots
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param timezone timezone or utc offset
     * @param stageId Stage Override Id
     * @returns AvailableDay successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsScheduleSlotsDays(
        accountSlug: string,
        applicationId: string,
        timezone?: string,
        stageId?: string,
    ): CancelablePromise<Array<AvailableDay>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots/days',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'timezone': timezone,
                'stage_id': stageId,
            },
        });
    }

    /**
     * Retrieves Available Slots
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param page Pagination defaults to 0
     * @param date Beginning of 24 hour slice
     * @param timezone timezone or utc offset
     * @param stageId Stage Override Id
     * @returns AvailableSlot successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsScheduleSlots(
        accountSlug: string,
        applicationId: string,
        page?: number,
        date?: string,
        timezone?: string,
        stageId?: string,
    ): CancelablePromise<Array<AvailableSlot>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'page': page,
                'date': date,
                'timezone': timezone,
                'stage_id': stageId,
            },
        });
    }

    /**
     * Create AvailableSlot from Cronofy Availability
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param requestBody Cronofy availability
     * @returns AvailableSlotDetail successful
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationsScheduleSlots(
        accountSlug: string,
        applicationId: string,
        requestBody: {
            start: string;
            end: string;
            sub: string;
            stage_id?: string;
        },
    ): CancelablePromise<AvailableSlotDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves Available Slot Details
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param slotId Available/Booked Slot External ID
     * @param stageId Stage Override Id
     * @returns AvailableSlotDetail successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsScheduleSlots1(
        accountSlug: string,
        applicationId: string,
        slotId: string,
        stageId?: string,
    ): CancelablePromise<AvailableSlotDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots/{slot_id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'slot_id': slotId,
            },
            query: {
                'stage_id': stageId,
            },
        });
    }

    /**
     * Book Available Slot
     * Books an Available Slot
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param slotId Available/Booked Slot External ID
     * @param stageId Stage Override Id
     * @param requestBody type of instant interview
     * @returns BookedSlot successful
     * @throws ApiError
     */
    public static patchInternalApiPortalApplicationsScheduleSlots(
        accountSlug: string,
        applicationId: string,
        slotId: string,
        stageId?: string,
        requestBody?: {
            instant_interview_type?: 'phone' | 'video';
            stage_id?: string;
        },
    ): CancelablePromise<BookedSlot> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots/{slot_id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'slot_id': slotId,
            },
            query: {
                'stage_id': stageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable`,
            },
        });
    }

    /**
     * Cancels a Booked Slot
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param slotId Available/Booked Slot External ID
     * @param stageId Stage Override Id
     * @returns BookedSlot successful
     * @throws ApiError
     */
    public static deleteInternalApiPortalApplicationsScheduleSlots(
        accountSlug: string,
        applicationId: string,
        slotId: string,
        stageId?: string,
    ): CancelablePromise<BookedSlot> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots/{slot_id}',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'slot_id': slotId,
            },
            query: {
                'stage_id': stageId,
            },
            errors: {
                422: `unprocessable entity`,
            },
        });
    }

    /**
     * Checks if booked slot can be rescheduled
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param slotId Available/Booked Slot External ID
     * @param stageId Stage Override Id
     * @returns Reschedulable successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsScheduleSlotsReschedulable(
        accountSlug: string,
        applicationId: string,
        slotId: string,
        stageId?: string,
    ): CancelablePromise<Reschedulable> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/schedule_slots/{slot_id}/reschedulable',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
                'slot_id': slotId,
            },
            query: {
                'stage_id': stageId,
            },
        });
    }

    /**
     * Retrieves Stage Progress for applicant
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsStageProgress(
        accountSlug: string,
        applicationId: string,
    ): CancelablePromise<{
        applicant_name: string;
        should_show_stages: boolean;
        progress_fraction?: string;
        progress_percentage?: number;
        current_stage: StageProgress;
        completed_stages?: Array<StageProgress>;
        upcoming_stages?: Array<StageProgress>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/stage_progress',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
        });
    }

    /**
     * Retrieves stage data based on stage type
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param gaeId Google Analytics Id
     * @param stageId Stage Override Id
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiPortalApplicationsStagesNew(
        accountSlug: string,
        applicationId: string,
        gaeId?: string,
        stageId?: string,
    ): CancelablePromise<((PortalStage & {
        assessment_description?: string;
        assessment_title: string;
        data_fields: DataFields;
        is_complete: boolean;
        show_all_questions_at_once: boolean;
        title: string;
        type: 'AssessmentStage';
    }) | (PortalStage & {
        completed: boolean;
        course_title?: string;
        course_url?: string;
        type: 'LearningStage';
    }) | (PortalStage & {
        completed: boolean;
        course_title?: string;
        course_url?: string;
        type: 'SchoolkeepStage';
    }) | (PortalStage & {
        type: 'MultiStage';
        sub_stages: Array<((SubStagePreview & {
            type: 'CustomStage' | 'SchedulerStage' | 'SchedulerV2Stage';
        }) | (SubStagePreview & {
            type: 'DataCollectionStage';
            data_fields_data: {
                data_fields_count: number;
                has_logic_jumps: boolean;
            };
        }) | (SubStagePreview & {
            type: 'DocumentSigningStage';
            document_count: number;
        }))>;
        is_complete: boolean;
    }) | (PortalStage & {
        show_all_questions_at_once: boolean;
        has_logic_jumps: boolean;
        show_data_collections_start_modal: boolean;
        is_last_stage: boolean;
        type: 'DataCollectionStage';
        form_description?: string;
        data_fields: DataFields;
        is_complete: boolean;
        file_upload?: FileUpload;
        form_title?: string;
    }) | (PortalStage & {
        type: 'BackgroundCheckerStage';
        title: string;
        instructions?: string;
        account_name?: string;
        account_slug?: string;
        after_consent_action?: string;
        completed?: boolean;
        embedded: boolean;
        invitation_url?: string;
        package_name: string;
        status?: string;
        first_name?: string;
        last_name?: string;
        email?: string;
        phone?: string;
        social_security?: string;
        driver_license_number?: string;
        driver_license_state?: string;
        include_personal_information?: boolean;
        include_driver_license?: boolean;
        error_message?: string;
    }) | (PortalStage & {
        type: 'CustomStage';
        portal_body: string;
        advance_url: string;
    }) | (PortalStage & {
        type: 'Stage';
    }) | (PortalStage & {
        type: 'HiredStage' | 'AccurateStage' | 'ArchivedStage' | 'RejectedStage';
    }) | (PortalStage & {
        type: 'SchedulerStage';
        title: string;
        instructions?: string;
        settings: {
            show_timezone: boolean;
            live_video: boolean;
            allow_advance_on_select_time_slot: boolean;
            calendar_zero: boolean;
            remote: boolean;
            funnel_time_zone: string;
        };
        booked_slot?: BookedSlot;
        available_time_zones: Array<AvailableTimeZone>;
        instant_interview?: AvailableSlot;
    }) | (PortalStage & {
        type: 'SchedulerV2Stage';
        title: string;
        instructions?: string;
        location?: string;
        settings: {
            show_timezone: boolean;
            live_video: boolean;
            allow_advance_on_select_time_slot: boolean;
            calendar_zero: boolean;
            remote: boolean;
            funnel_time_zone: string;
        };
        booked_slot?: BookedSlot;
        available_time_zones: Array<AvailableTimeZone>;
        instant_interview?: AvailableSlot;
        element_token?: string;
        availability_query?: AvailabilityQuery;
    }) | (PortalStage & {
        type: 'RuleStage';
    }) | (PortalStage & ({
        type: 'DocumentSigningStage';
        variant: 'embedded_hellosign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'white_labeled_hellosign';
            client_id: string;
            test_mode: boolean;
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
        signature_requests: Array<{
            signature_id: string;
            title: string;
            message: string;
            templates?: Array<{
                title: string;
                message: string;
            }>;
            is_signed: boolean;
        }>;
    } | {
        type: 'DocumentSignatureStage';
        variant: 'embedded_docusign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'docusign';
        };
        signature_requests: Array<{
            signature_id: string;
            templates: Array<string>;
        }>;
        status: 'completed' | 'pending' | 'ready' | 'error' | 'in_progress';
        has_template: boolean;
    } | {
        type: 'DocumentSignatureStage';
        variant: 'docusign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'docusign';
        };
        signature_requests: Array<{
            signature_id: string;
            templates: Array<string>;
        }>;
        status: 'completed' | 'pending' | 'ready' | 'error' | 'in_progress';
        has_template: boolean;
    } | {
        type: 'DocumentSignatureStage';
        variant: 'hellosign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'hellosign';
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
    })) | (PortalStage & {
        type: 'W4StateFormStage';
        title: string;
        instructions?: string;
        document_signature_provider: {
            client_id?: string;
            test_mode?: boolean;
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
        hellosign_request?: Array<{
            signature_id: string;
            title: string;
            is_signed: boolean;
        }>;
        state: 'AL' | 'AK' | 'AZ' | 'AR' | 'CA' | 'CO' | 'CT' | 'DE' | 'DC' | 'FL' | 'GA' | 'HI' | 'ID' | 'IL' | 'IN' | 'IA' | 'KS' | 'KY' | 'LA' | 'ME' | 'MD' | 'MA' | 'MI' | 'MN' | 'MS' | 'MO' | 'MT' | 'NE' | 'NV' | 'NH' | 'NJ' | 'NM' | 'NY' | 'NC' | 'ND' | 'OH' | 'OK' | 'OR' | 'PA' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VT' | 'VA' | 'WA' | 'WV' | 'WI' | 'WY';
        applicant_signed: boolean;
        auto_advance_on_completion: boolean;
    }) | (PortalStage & {
        type: 'W4FederalFormStage';
        title: string;
        instructions?: string;
        document_signature_provider: {
            client_id?: string;
            test_mode?: boolean;
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
        hellosign_federal_request?: Array<{
            signature_id: string;
            title: string;
            is_signed: boolean;
        }>;
        applicant_signed: boolean;
        auto_advance_on_completion: boolean;
    }) | (PortalStage & {
        type: 'PartnerStage';
        completed?: boolean;
        partner_name: string;
        redirect_url?: string;
        applicant_portal_action: 'refresh' | 'redirect' | 'redirect_via_iframe' | 'redirect_via_modal_iframe' | 'data_collection';
        data_fields?: DataFields;
        file_upload?: FileUpload;
        partner_employment_history_additional_questions_enabled?: boolean;
        consents?: PartnerConsents;
        consent_required?: boolean;
        completed_copy_key?: PartnerCompletedCopyKeys;
        applicant_first_name?: string;
        applicant_last_name?: string;
        applicant_email?: string;
    }) | (PortalStage & {
        type: 'VideoRecordingStage';
        remaining_question_ids: Array<number>;
        questions: Array<VideoRecordingStageQuestion>;
        is_complete: boolean;
    }) | (PortalStage & {
        type: 'JobSelectorStage' | 'JobSwitcherStage';
        locations?: Array<{
            location_address?: string;
            positions?: Array<JobRouterPosition>;
            distance_in_miles?: number;
        }>;
        priority_locations?: Array<{
            location_address?: string;
            positions?: Array<JobRouterPosition>;
            distance_in_miles?: number;
        }>;
        opening_conditions_present: boolean;
        advance_url?: string;
    }) | (PortalStage & {
        type: 'I9FormStage';
        applicant_first_name: string;
        applicant_last_name: string;
        applicant_email: string;
        applicant_phone_number: string;
        is_complete: boolean;
        employee_upload_configs: FileUpload;
        preparer_upload_configs: FileUpload;
        i9_form_external_id?: string;
        i9_compliance_2023_enabled?: boolean;
        status: 'awaiting_employee' | 'awaiting_employer' | 'pending' | 'complete' | 'opted_out' | 'awaiting_authorized_representative';
    }) | (PortalStage & {
        type: 'TechCheckStage';
        customer_support?: {
            phone_number: string;
            url: string;
        };
        settings: Array<TechCheckStageSetting>;
    }) | (PortalStage & {
        type: 'EventStage';
        title: string;
        instructions?: string;
        settings: {
            show_timezone: boolean;
            live_video: boolean;
            allow_advance_on_select_time_slot: boolean;
            calendar_zero: boolean;
            remote: boolean;
            funnel_time_zone: string;
        };
        booked_slot?: BookedSlot;
        available_time_zones: Array<AvailableTimeZone>;
        instant_interview?: AvailableSlot;
    }))> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/stages/new',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'gae_id': gaeId,
                'stage_id': stageId,
            },
        });
    }

    /**
     * Completes Stage for Applicant
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param formData Data in key value pairs
     * @param stageId Stage Override Id
     * @returns any records created
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationsStages(
        accountSlug: string,
        applicationId: string,
        formData: Record<string, Record<string, string>>,
        stageId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/stages',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            query: {
                'stage_id': stageId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Translates a given text
     * @param requestBody
     * @returns string successful
     * @throws ApiError
     */
    public static postInternalApiPortalTranslationTranslate(
        requestBody: {
            /**
             * Source langage
             */
            src: string;
            /**
             * Destination langage
             */
            to: string;
            /**
             * Text to translate
             */
            text: string;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/translation/translate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Translates data fields
     * @param accountSlug Account Slug
     * @param funnelId Funnel ID
     * @param requestBody
     * @returns DataFields successful
     * @throws ApiError
     */
    public static postInternalApiPortalFunnelsTranslationDataFields(
        accountSlug: string,
        funnelId: string,
        requestBody: {
            /**
             * Destination langage
             */
            to: string;
            /**
             * Data fields to translate
             */
            field_ids: Array<number>;
        },
    ): CancelablePromise<Array<DataFields>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/funnels/{funnel_id}/translation/data_fields',
            path: {
                'account_slug': accountSlug,
                'funnel_id': funnelId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Translates stages
     * @param accountSlug Account Slug
     * @param funnelId Funnel ID
     * @param requestBody
     * @param applicationId Application ID
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiPortalFunnelsTranslationStages(
        accountSlug: string,
        funnelId: string,
        requestBody: {
            /**
             * Destination langage
             */
            to: string;
            /**
             * Stages to translate
             */
            stage_ids: Array<number>;
        },
        applicationId?: string,
    ): CancelablePromise<Array<((PortalStage & {
        completed: boolean;
        course_title?: string;
        course_url?: string;
        type: 'LearningStage';
    }) | (PortalStage & {
        completed: boolean;
        course_title?: string;
        course_url?: string;
        type: 'SchoolkeepStage';
    }) | (PortalStage & {
        type: 'MultiStage';
        sub_stages: Array<((SubStagePreview & {
            type: 'CustomStage' | 'SchedulerStage' | 'SchedulerV2Stage';
        }) | (SubStagePreview & {
            type: 'DataCollectionStage';
            data_fields_data: {
                data_fields_count: number;
                has_logic_jumps: boolean;
            };
        }) | (SubStagePreview & {
            type: 'DocumentSigningStage';
            document_count: number;
        }))>;
        is_complete: boolean;
    }) | (PortalStage & {
        show_all_questions_at_once: boolean;
        has_logic_jumps: boolean;
        show_data_collections_start_modal: boolean;
        is_last_stage: boolean;
        type: 'DataCollectionStage';
        form_description?: string;
        data_fields: DataFields;
        is_complete: boolean;
        file_upload?: FileUpload;
        form_title?: string;
    }) | (PortalStage & {
        type: 'BackgroundCheckerStage';
        title: string;
        instructions?: string;
        account_name?: string;
        account_slug?: string;
        after_consent_action?: string;
        completed?: boolean;
        embedded: boolean;
        invitation_url?: string;
        package_name: string;
        status?: string;
        first_name?: string;
        last_name?: string;
        email?: string;
        phone?: string;
        social_security?: string;
        driver_license_number?: string;
        driver_license_state?: string;
        include_personal_information?: boolean;
        include_driver_license?: boolean;
        error_message?: string;
    }) | (PortalStage & {
        type: 'CustomStage';
        portal_body: string;
        advance_url: string;
    }) | (PortalStage & {
        type: 'Stage';
    }) | (PortalStage & {
        type: 'HiredStage' | 'AccurateStage' | 'ArchivedStage' | 'RejectedStage';
    }) | (PortalStage & {
        type: 'SchedulerStage';
        title: string;
        instructions?: string;
        settings: {
            show_timezone: boolean;
            live_video: boolean;
            allow_advance_on_select_time_slot: boolean;
            calendar_zero: boolean;
            remote: boolean;
            funnel_time_zone: string;
        };
        booked_slot?: BookedSlot;
        available_time_zones: Array<AvailableTimeZone>;
        instant_interview?: AvailableSlot;
    }) | (PortalStage & {
        type: 'SchedulerV2Stage';
        title: string;
        instructions?: string;
        location?: string;
        settings: {
            show_timezone: boolean;
            live_video: boolean;
            allow_advance_on_select_time_slot: boolean;
            calendar_zero: boolean;
            remote: boolean;
            funnel_time_zone: string;
        };
        booked_slot?: BookedSlot;
        available_time_zones: Array<AvailableTimeZone>;
        instant_interview?: AvailableSlot;
        element_token?: string;
        availability_query?: AvailabilityQuery;
    }) | (PortalStage & ({
        type: 'DocumentSigningStage';
        variant: 'embedded_hellosign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'white_labeled_hellosign';
            client_id: string;
            test_mode: boolean;
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
        signature_requests: Array<{
            signature_id: string;
            title: string;
            message: string;
            templates?: Array<{
                title: string;
                message: string;
            }>;
            is_signed: boolean;
        }>;
    } | {
        type: 'DocumentSignatureStage';
        variant: 'embedded_docusign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'docusign';
        };
        signature_requests: Array<{
            signature_id: string;
            templates: Array<string>;
        }>;
        status: 'completed' | 'pending' | 'ready' | 'error' | 'in_progress';
        has_template: boolean;
    } | {
        type: 'DocumentSignatureStage';
        variant: 'docusign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'docusign';
        };
        signature_requests: Array<{
            signature_id: string;
            templates: Array<string>;
        }>;
        status: 'completed' | 'pending' | 'ready' | 'error' | 'in_progress';
        has_template: boolean;
    } | {
        type: 'DocumentSignatureStage';
        variant: 'hellosign';
        title: string;
        instructions?: string;
        document_signature_provider: {
            provider_name: 'hellosign';
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
    })) | (PortalStage & {
        type: 'W4StateFormStage';
        title: string;
        instructions?: string;
        document_signature_provider: {
            client_id?: string;
            test_mode?: boolean;
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
        hellosign_request?: Array<{
            signature_id: string;
            title: string;
            is_signed: boolean;
        }>;
        state: 'AL' | 'AK' | 'AZ' | 'AR' | 'CA' | 'CO' | 'CT' | 'DE' | 'DC' | 'FL' | 'GA' | 'HI' | 'ID' | 'IL' | 'IN' | 'IA' | 'KS' | 'KY' | 'LA' | 'ME' | 'MD' | 'MA' | 'MI' | 'MN' | 'MS' | 'MO' | 'MT' | 'NE' | 'NV' | 'NH' | 'NJ' | 'NM' | 'NY' | 'NC' | 'ND' | 'OH' | 'OK' | 'OR' | 'PA' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VT' | 'VA' | 'WA' | 'WV' | 'WI' | 'WY';
        applicant_signed: boolean;
        auto_advance_on_completion: boolean;
    }) | (PortalStage & {
        type: 'W4FederalFormStage';
        title: string;
        instructions?: string;
        document_signature_provider: {
            client_id?: string;
            test_mode?: boolean;
        };
        status: 'completed' | 'pending' | 'ready' | 'error';
        has_template: boolean;
        hellosign_federal_request?: Array<{
            signature_id: string;
            title: string;
            is_signed: boolean;
        }>;
        applicant_signed: boolean;
        auto_advance_on_completion: boolean;
    }) | (PortalStage & {
        type: 'PartnerStage';
        completed?: boolean;
        partner_name: string;
        redirect_url?: string;
        applicant_portal_action: 'refresh' | 'redirect' | 'redirect_via_iframe' | 'redirect_via_modal_iframe' | 'data_collection';
        data_fields?: DataFields;
        file_upload?: FileUpload;
        partner_employment_history_additional_questions_enabled?: boolean;
        consents?: PartnerConsents;
        consent_required?: boolean;
        completed_copy_key?: PartnerCompletedCopyKeys;
        applicant_first_name?: string;
        applicant_last_name?: string;
        applicant_email?: string;
    }) | (PortalStage & {
        type: 'VideoRecordingStage';
        remaining_question_ids: Array<number>;
        questions: Array<VideoRecordingStageQuestion>;
        is_complete: boolean;
    }) | (PortalStage & {
        type: 'I9FormStage';
        applicant_first_name: string;
        applicant_last_name: string;
        applicant_email: string;
        applicant_phone_number: string;
        is_complete: boolean;
        employee_upload_configs: FileUpload;
        preparer_upload_configs: FileUpload;
        i9_form_external_id?: string;
        i9_compliance_2023_enabled?: boolean;
        status: 'awaiting_employee' | 'awaiting_employer' | 'pending' | 'complete' | 'opted_out' | 'awaiting_authorized_representative';
    }) | (PortalStage & {
        type: 'TechCheckStage';
        customer_support?: {
            phone_number: string;
            url: string;
        };
        settings: Array<TechCheckStageSetting>;
    }) | (PortalStage & {
        type: 'EventStage';
        title: string;
        instructions?: string;
        settings: {
            show_timezone: boolean;
            live_video: boolean;
            allow_advance_on_select_time_slot: boolean;
            calendar_zero: boolean;
            remote: boolean;
            funnel_time_zone: string;
        };
        booked_slot?: BookedSlot;
        available_time_zones: Array<AvailableTimeZone>;
        instant_interview?: AvailableSlot;
    }))>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/funnels/{funnel_id}/translation/stages',
            path: {
                'account_slug': accountSlug,
                'funnel_id': funnelId,
            },
            query: {
                'application_id': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Translates a funnel
     * @param accountSlug Account Slug
     * @param funnelId Funnel ID
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiPortalFunnelsTranslation(
        accountSlug: string,
        funnelId: string,
        requestBody: {
            /**
             * Destination langage
             */
            to: string;
            /**
             * Persona ID
             */
            persona_id?: string;
        },
    ): CancelablePromise<{
        position_description_html?: string;
        translated?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/funnels/{funnel_id}/translation',
            path: {
                'account_slug': accountSlug,
                'funnel_id': funnelId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * creates recording with video metadata
     * @param accountSlug Account Slug
     * @param applicationId Applicant External ID
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiPortalApplicationsVideoRecordings(
        accountSlug: string,
        applicationId: string,
        requestBody?: VideoRecordingStageRecordingAttributes,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/portal/{account_slug}/applications/{application_id}/video_recordings',
            path: {
                'account_slug': accountSlug,
                'application_id': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `conflict`,
                500: `Recording could not be saved`,
            },
        });
    }

}