import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';

export const QUESTION_TYPE_OPTIONS = [
  {
    value: ReviewStageDataFieldTypeEnum.textField as const,
    label: 'Text',
  },
  {
    value: ReviewStageDataFieldTypeEnum.radioField as const,
    label: 'Multiple Choice',
  },
];
export type QuestionTypeOptions = typeof QUESTION_TYPE_OPTIONS;
